<template>
    <div style="background-color: #f9fefe; padding: 13px; width: auto">
        <a-row style="margin-bottom: 10px">
            <a-form ref="formRule" :model="form" :rules="rules" layout="inline">
                <a-form-item>
                    <a-button type="primary" @click="add">
                        컨텐츠 추가
                    </a-button>
                </a-form-item>
                <a-form-item>
                    <a-button type="primary" @click="testWeb">
                        이벤트 페이지 미리보기
                    </a-button>
                </a-form-item>
                <a-form-item v-if="selectedMenu.includes('request')">
                    <a-button type="primary" @click="testWeb2">
                        참가 페이지 미리보기
                    </a-button>
                </a-form-item>
                <a-form-item v-if="selectedMenu.includes('survey')">
                    <a-button type="primary" @click="testSurvey">
                        설문 페이지 미리보기
                    </a-button>
                </a-form-item>
            </a-form>
        </a-row>
        <a-menu
            v-model:selectedKeys="selectedMenu"
            :items="menu"
            mode="horizontal"
        />
        <a-row v-if="selectedMenu.includes('request')" style="margin-top: 20px">
            <draggable :list="valueRef.contentList" item-key="value">
                <template #item="{ element, index }">
                    <a-form
                        :model="element"
                        :rules="rules"
                        layout="inline"
                        style="
                            padding-bottom: 10px;
                            display: block;
                            border: 2px solid black;
                            border-radius: 10px;
                            margin-bottom: 10px;
                            padding: 20px;
                        "
                    >
                        <a-form-item>
                            <span v-text="index + 1"></span>
                            <a-button type="danger" @click="() => pop(index)">
                                <template #icon>
                                    <delete-outlined />
                                </template>
                            </a-button>
                        </a-form-item>
                        <div
                            style="
                                display: flex;
                                align-items: center;
                                margin-bottom: 10px;
                            "
                        >
                            <label style="width: 100px">컨텐츠 아이디: </label>
                            <a-input v-model:value="element.contentId" />
                        </div>
                        <div
                            style="
                                display: flex;
                                align-items: center;
                                margin-bottom: 10px;
                            "
                        >
                            <label style="width: 100px">컨텐츠 이름: </label>
                            <a-input v-model:value="element.name" />
                        </div>
                        <content-image-input
                            v-model:value="element.resourceUrl"
                            name="resourceUrl"
                            path="/event-content"
                            style="display: flex"
                        ></content-image-input>
                        <div class="input-row">
                            <label style="width: 100%">컨텐츠 타입: </label>
                            <enum-select
                                v-model:value="element.contentType"
                                typeName="event-content-type"
                            ></enum-select>
                        </div>
                        <div class="input-row">
                            <label style="width: 100%">노출 타입: </label>
                            <enum-select
                                v-model:value="element.exposeType"
                                typeName="event-content-expose-type"
                            ></enum-select>
                        </div>
                        <div class="input-row">
                            <label style="width: 100%">액션 타입: </label>
                            <enum-select
                                v-model:value="element.actionType"
                                typeName="event-action-type"
                            ></enum-select>
                        </div>
                        <div class="input-row">
                            <label style="width: 100px">액션파라미터: </label>
                            <a-textarea
                                v-model:value="element.actionParameter"
                            />
                        </div>
                    </a-form>
                </template>
            </draggable>
        </a-row>
        <a-row v-if="selectedMenu.includes('proof')" style="margin-top: 20px">
            <draggable :list="valueRef.proofContentList" item-key="value">
                <template #item="{ element, index }">
                    <a-form
                        :model="element"
                        :rules="rules"
                        layout="inline"
                        style="
                            padding-bottom: 10px;
                            display: block;
                            border: 2px solid black;
                            border-radius: 10px;
                            margin-bottom: 10px;
                            padding: 20px;
                        "
                    >
                        <a-form-item>
                            <span v-text="index + 1"></span>
                            <a-button type="danger" @click="() => pop(index)">
                                <template #icon>
                                    <delete-outlined />
                                </template>
                            </a-button>
                        </a-form-item>
                        <div
                            style="
                                display: flex;
                                align-items: center;
                                margin-bottom: 10px;
                            "
                        >
                            <label style="width: 100px">컨텐츠 아이디: </label>
                            <a-input v-model:value="element.contentId" />
                        </div>
                        <div
                            style="
                                display: flex;
                                align-items: center;
                                margin-bottom: 10px;
                            "
                        >
                            <label style="width: 100px">컨텐츠 이름: </label>
                            <a-input v-model:value="element.name" />
                        </div>
                        <content-image-input
                            v-model:value="element.resourceUrl"
                            name="resourceUrl"
                            path="/event-content"
                            style="display: flex"
                        ></content-image-input>
                        <div class="input-row">
                            <label style="width: 100%">컨텐츠 타입: </label>
                            <enum-select
                                v-model:value="element.contentType"
                                typeName="event-content-type"
                            ></enum-select>
                        </div>
                        <div class="input-row">
                            <label style="width: 100%">노출 타입: </label>
                            <enum-select
                                v-model:value="element.exposeType"
                                typeName="event-content-expose-type"
                            ></enum-select>
                        </div>
                        <div class="input-row">
                            <label style="width: 100%">액션 타입: </label>
                            <enum-select
                                v-model:value="element.actionType"
                                typeName="event-action-type"
                            ></enum-select>
                        </div>
                        <div class="input-row">
                            <label style="width: 100px">액션파라미터: </label>
                            <a-textarea
                                v-model:value="element.actionParameter"
                            />
                        </div>
                    </a-form>
                </template>
            </draggable>
        </a-row>
        <a-row v-if="selectedMenu.includes('survey')" style="margin-top: 20px">
            <draggable :list="valueRef.surveyContentList" item-key="value">
                <template #item="{ element, index }">
                    <a-form
                        :model="element"
                        :rules="rules"
                        layout="inline"
                        style="
                            padding-bottom: 10px;
                            display: block;
                            border: 2px solid black;
                            border-radius: 10px;
                            margin-bottom: 10px;
                            padding: 20px;
                        "
                    >
                        <a-form-item>
                            <span v-text="index + 1"></span>
                            <a-button type="danger" @click="() => pop(index)">
                                <template #icon>
                                    <delete-outlined />
                                </template>
                            </a-button>
                        </a-form-item>
                        <div
                            style="
                                display: flex;
                                align-items: center;
                                margin-bottom: 10px;
                            "
                        >
                            <label style="width: 100px">컨텐츠 아이디: </label>
                            <a-input v-model:value="element.contentId" />
                        </div>
                        <div
                            style="
                                display: flex;
                                align-items: center;
                                margin-bottom: 10px;
                            "
                        >
                            <label style="width: 100px">컨텐츠 이름: </label>
                            <a-input v-model:value="element.name" />
                        </div>
                        <div
                            style="
                                display: flex;
                                align-items: center;
                                margin-bottom: 10px;
                            "
                            v-if="element.contentType !== 'IMAGE'"
                        >
                            <label style="width: 100px">서브타이틀: </label>
                            <a-input v-model:value="element.subName" />
                        </div>
                        <content-image-input
                            v-if="element.contentType === 'IMAGE'"
                            v-model:value="element.resourceUrl"
                            name="resourceUrl"
                            path="/event-content"
                            style="display: flex"
                        ></content-image-input>
                        <div class="input-row">
                            <label style="width: 100%">컨텐츠 타입: </label>
                            <enum-select
                                v-model:value="element.contentType"
                                typeName="event-survey-type"
                            ></enum-select>
                        </div>
                        <div class="input-row">
                            <label style="width: 100%">노출 타입: </label>
                            <enum-select
                                v-model:value="element.exposeType"
                                typeName="event-content-expose-type"
                            ></enum-select>
                        </div>
                        <div class="input-row">
                            <label style="width: 100%">액션 타입: </label>
                            <enum-select
                                v-model:value="element.actionType"
                                typeName="event-action-type"
                            ></enum-select>
                        </div>
                        <div class="input-row">
                            <label style="width: 100px">액션파라미터: </label>
                            <a-textarea
                                v-model:value="element.actionParameter"
                            />
                        </div>
                    </a-form>
                </template>
            </draggable>
        </a-row>
        <a-row v-if="selectedMenu.includes('quiz')" style="margin-top: 20px">
            <draggable :list="valueRef.quizContentList" item-key="value">
                <template #item="{ element, index }">
                    <a-form
                        :model="element"
                        :rules="rules"
                        layout="inline"
                        style="
                            padding-bottom: 10px;
                            display: block;
                            border: 2px solid black;
                            border-radius: 10px;
                            margin-bottom: 10px;
                            padding: 20px;
                        "
                    >
                        <a-form-item>
                            <span v-text="index + 1"></span>
                            <a-button type="danger" @click="() => pop(index)">
                                <template #icon>
                                    <delete-outlined />
                                </template>
                            </a-button>
                        </a-form-item>
                        <div
                            style="
                                display: flex;
                                align-items: center;
                                margin-bottom: 10px;
                            "
                        >
                            <label style="width: 100px">컨텐츠 아이디: </label>
                            <a-input v-model:value="element.contentId" />
                        </div>
                        <div
                            style="
                                display: flex;
                                align-items: center;
                                margin-bottom: 10px;
                            "
                        >
                            <label style="width: 100px">컨텐츠 이름: </label>
                            <a-input v-model:value="element.name" />
                        </div>
                        <div
                            style="
                                display: flex;
                                align-items: center;
                                margin-bottom: 10px;
                            "
                            v-if="element.contentType !== 'IMAGE'"
                        >
                            <label style="width: 100px">서브타이틀: </label>
                            <a-input v-model:value="element.subName" />
                        </div>
                        <content-image-input
                            v-if="element.contentType === 'IMAGE'"
                            v-model:value="element.resourceUrl"
                            name="resourceUrl"
                            path="/event-content"
                            style="display: flex"
                        ></content-image-input>
                        <div class="input-row">
                            <label style="width: 100%">컨텐츠 타입: </label>
                            <enum-select
                                v-model:value="element.contentType"
                                typeName="event-survey-type"
                            ></enum-select>
                        </div>
                        <div class="input-row">
                            <label style="width: 100%">노출 타입: </label>
                            <enum-select
                                v-model:value="element.exposeType"
                                typeName="event-content-expose-type"
                            ></enum-select>
                        </div>
                        <div class="input-row">
                            <label style="width: 100%">액션 타입: </label>
                            <enum-select
                                v-model:value="element.actionType"
                                typeName="event-action-type"
                            ></enum-select>
                        </div>
                        <div class="input-row">
                            <label style="width: 100px">액션파라미터: </label>
                            <a-textarea
                                v-model:value="element.actionParameter"
                            />
                        </div>
                        <div class="input-row">
                            <span class="input-header">속성</span>
                            <event-content-properties-input
                                v-model:value="element.properties"
                            />
                        </div>
                    </a-form>
                </template>
            </draggable>
        </a-row>
    </div>
</template>

<style>
.input-row {
    display: flex;
    align-items: center;
    margin-bottom: 5px;
}
.input-header {
    display: flex;
    min-width: 100px;
    font-size: 16px;
    font-weight: 700;
}
</style>

<script setup lang="ts">
import { computed, onMounted, ref, toRef } from 'vue'
import { aboveOrEqualToZero, required } from '@/util/input-validation'
import Draggable from 'vuedraggable'
import { DeleteOutlined } from '@ant-design/icons-vue'
import { useEnumTypeStore } from '@/store/enumType'
import ContentImageInput from '@/components/input/ContentImageInput.vue'
import EnumSelect from '@/components/input/EnumSelect.vue'
import { webBaseUrl } from '@/fetchTemplate'
import { message } from 'ant-design-vue'
import EventContentPropertiesInput from '@/components/input/EventContentPropertiesInput.vue'

type EventContent = {
    contentId: string
    name: string
    subName: string
    resourceUrl: string
    contentType: string
    exposeType: string
    actionType: string
    actionParameter: string
    properties: any
}

const props = defineProps({
    rules: {
        type: Object,
        default: () => ({
            name: [required],
            resourceUrl: [required],
            orderNo: [required, aboveOrEqualToZero],
            contentType: [required],
            exposeType: [required],
            actionType: [required],
        }),
    },
    resourceId: {
        type: Number,
    },
    value: {
        type: Object,
        default: () => {},
    },
})

const form = ref<EventContent>({
    contentId: '',
    name: '',
    subName: '',
    resourceUrl: '',
    contentType: 'IMAGE',
    exposeType: 'ALL',
    actionType: 'NONE',
    actionParameter: '',
    properties: {
        quizAnswer: '',
        quizComment: '',
    },
})

const formRule = ref()
const valueRef = toRef(props, 'value')

const menu = [
    {
        key: 'request',
        label: '신청',
        title: '신청',
    },
    {
        key: 'proof',
        label: '인증',
        title: '인증',
    },
    {
        key: 'survey',
        label: '설문',
        title: '설문',
    },
    {
        key: 'quiz',
        label: '퀴즈',
        title: '퀴즈',
    },
]
const selectedMenu = ref(['request'])

const push = (value: EventContent) => {
    if (selectedMenu.value.includes('request')) {
        valueRef.value.contentList.push(value)
    } else if (selectedMenu.value.includes('proof')) {
        valueRef.value.proofContentList.push(value)
    } else if (selectedMenu.value.includes('survey')) {
        valueRef.value.surveyContentList.push(value)
    } else if (selectedMenu.value.includes('quiz')) {
        valueRef.value.quizContentList.push(value)
    }
}

const pop = (index: number) => {
    if (selectedMenu.value.includes('request')) {
        valueRef.value.contentList.splice(index, 1)
    } else if (selectedMenu.value.includes('proof')) {
        valueRef.value.proofContentList.splice(index, 1)
    } else if (selectedMenu.value.includes('survey')) {
        valueRef.value.surveyContentList.splice(index, 1)
    } else if (selectedMenu.value.includes('quiz')) {
        valueRef.value.quizContentList.splice(index, 1)
    }
}

const add = () => {
    return formRule.value.validate().then(() => {
        if (props.value != null) {
            push(form.value)
            console.log('add called')
            message.info('추가되었습니다.')
            form.value = {
                contentId: '',
                name: '',
                subName: '',
                resourceUrl: '',
                contentType: 'IMAGE',
                exposeType: 'ALL',
                actionType: 'NONE',
                actionParameter: '',
                properties: {
                    quizAnswer: '',
                    quizComment: '',
                },
            }
        }
    })
}

const testWeb = () => {
    const now = new Date()
    const isProof = selectedMenu.value.includes('proof')

    const year = now.getFullYear()
    const month = String(now.getMonth() + 1).padStart(2, '0')
    const day = String(now.getDate()).padStart(2, '0')
    const hours = String(now.getHours()).padStart(2, '0')
    const minutes = String(now.getMinutes()).padStart(2, '0')
    const dateParam = `${year}${month}${day}${hours}${minutes}`
    const link = `${webBaseUrl}/event/${props.resourceId}/test?date=${dateParam}&proof=${isProof}`
    window.open(link)
}

const testWeb2 = () => {
    const now = new Date()

    const year = now.getFullYear()
    const month = String(now.getMonth() + 1).padStart(2, '0')
    const day = String(now.getDate()).padStart(2, '0')
    const hours = String(now.getHours()).padStart(2, '0')
    const minutes = String(now.getMinutes()).padStart(2, '0')
    const dateParam = `${year}${month}${day}${hours}${minutes}`
    const link = `${webBaseUrl}/event/${props.resourceId}/join/test?date=${dateParam}`
    window.open(link)
}

const testSurvey = () => {
    const now = new Date()

    const year = now.getFullYear()
    const month = String(now.getMonth() + 1).padStart(2, '0')
    const day = String(now.getDate()).padStart(2, '0')
    const hours = String(now.getHours()).padStart(2, '0')
    const minutes = String(now.getMinutes()).padStart(2, '0')
    const dateParam = `${year}${month}${day}${hours}${minutes}`
    const link = `${webBaseUrl}/event/${props.resourceId}/survey/test`
    window.open(link)
}

onMounted(() => {
    const enumTypeStore = useEnumTypeStore()
    enumTypeStore.dispatchEnums(['event-content-type'])
    enumTypeStore.dispatchEnums(['event-survey-type'])
    enumTypeStore.dispatchEnums(['event-content-expose-type'])
    enumTypeStore.dispatchEnums(['event-action-type'])
})
</script>
